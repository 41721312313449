import React from 'react';
import LinksController from '../../../controllers/LinksController';
import LuhkuInfoCards from './luhku_info_cards/LuhkuInfoCards';
import './styles.css';

export default function ShoppingSection() {
  const links = LinksController;
  return (
    <div className="shopping-section home-section">
      <h2 className="section-title">
        We make shopping Convenient
      </h2>
      <div className="convinient-underline" />
      <p className="section-sub-title">
        Look Good, Feel Good! Join our community of fashion lovers and 🔥 outfits
      </p>
      <a href={links.buyerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button shop-now-btn-shopping btn-text desktop-shop-btn">
        Shop Now
      </a>
      <div className="shopping-row">
        <div className="shopping_lady" />
        <LuhkuInfoCards />
      </div>
      <a href={links.buyerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button shop-now-btn-shopping btn-text mobile-shop-btn">
        Shop Now
      </a>
    </div>
  );
}
