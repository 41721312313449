import React from 'react';
import LinksController from '../../../controllers/LinksController';
import './styles.css';

export default function HomeInto() {
  const links = LinksController;
  return (
    <div className="into-cover">
      <div className="intro-container">
        {/* <h2>
        The fastest and safest way
        to buy and sell fashion online.
      </h2>
      <p>Variety, secure payments, and convenient deliveries on one app.</p> */}
        <div className="btn-options">
          <a href={links.buyerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button shop-now-btn btn-text">
            Shop Now
          </a>
          <a href={links.sellerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button start-selling-btn btn-text">
            Start Selling
          </a>
        </div>
        {/* <div className="luhku-swag-image" />
      <div className="fastest-underline" />
      <div className="safest-underline" /> */}
      </div>
    </div>
  );
}
