import React from 'react';
import SocialController from '../../../controllers/SocialController';
import AboutLukhu from '../AboutLukhu';
import HelpCenter from '../HelpCenter';
import SocialIconCard from '../SocialIconCard';
import './styles.css';

export default function MobileFooter() {
  const icons = new SocialController().socials;
  return (
    <div className="mobile-footer">
      <div className="lukhu-logo-mobile" />
      <p className="footer-insight-text">The Operating System for Fashion Ecommerce in Africa.</p>
      <div className="links-sections-mobile">
        <AboutLukhu />
        <HelpCenter />
      </div>
      <div className="social-icons-row">
        {icons.map((i) => (<SocialIconCard key={i.icon} icon={i.icon} link={i.link} />))}
      </div>
      <div className="luhku-footer-line" />
      <p className="luhku-inc-mobile">
        {`Copyright @ Lukhu Inc. ${new Date().getFullYear()} All Rights Reserved.`}

      </p>
    </div>
  );
}
