export default class LinksController {
  static get buyerLink() { return 'https://lukhu.co.ke/my-profile'; }

  static get howToSell() { return 'https://lukhu.helpscoutdocs.com/article/32-how-to-sell'; }

  static get howToBuy() { return 'https://lukhu.helpscoutdocs.com/article/33-how-to-buy'; }

  static get sellerLink() { return 'https://lukhu.co.ke/my-profile/listed-items'; }

  static get blogLink() { return 'https://lukhu.medium.com/'; }

  static get termsLink() { return 'https://lukhu.helpscoutdocs.com/article/22-terms-and-conditions'; }

  static get privacyLink() { return 'https://lukhu.helpscoutdocs.com/article/25-lukhu-privacy-policy'; }

  static get careersLink() { return 'https://lukhu.freshteam.com/jobs'; }

  static get playStoreLink() { return 'https://play.google.com/store/apps/details?id=com.kalu.lukhu'; }

  static get appleStoreLink() { return 'https://testflight.apple.com/join/wc6TSH02'; }

  static get searchLink() { return 'https://lukhu.co.ke/'; }
}
