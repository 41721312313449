import React, { useState } from 'react';
import subscribeToMailingList from '../../api/ApiCalls';
import Loading from '../Loading';

export default function SubscriptionCard() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const subscribe = async () => {
    if (email !== '' && email !== null && email !== undefined && email.includes('@')) {
      setLoading(true);
      const res = await subscribeToMailingList(email).catch(() => { });
      setLoading(false);
      if (res.status === 'success') {
        setEmail('');
      }

      setMessage(res.message);
    }
  };
  return (
    <div className="section-columns">
      <h4>
        Subscribe for news and amazing offers!
      </h4>
      <div className="subscription-field-card">
        <form>
          <input
            className="email-input-field"
            type="email"
            value={email}
            placeholder="Your email address"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {!loading ? (<input className="subsription-btn" type="submit" value="Subscribe" onClick={subscribe} />) : (<Loading mini />)}
          {message !== '' && <p className="subscription-message">{message}</p>}
        </form>
      </div>
    </div>
  );
}
