import React from 'react';
import LinksController from '../../controllers/LinksController';

export default function AboutLukhu() {
  const links = LinksController;
  return (
    <div className="section-columns">
      <h4>
        About Lukhu
      </h4>
      <a href={links.sellerLink} target="_blank" rel="noreferrer noopener">For Sellers</a>
      <a href={links.buyerLink} target="_blank" rel="noreferrer noopener">For Buyers</a>
      <a href={links.blogLink} target="_blank" rel="noreferrer noopener">Our Blog</a>
      <a href={links.careersLink} target="_blank" rel="noreferrer noopener">Careers</a>
    </div>
  );
}
