import React from 'react';
import AboutLukhu from './AboutLukhu';
import HelpCenter from './HelpCenter';
import LuhkuSocialCard from './luhku_social/LuhkuSocialCard';
import './styles.css';
import SubscriptionCard from './SubscriptionCard';

export default function LuhkuFooter() {
  return (
    <div className="luhku-footer">
      <div className="footer-section">
        <LuhkuSocialCard />
        <AboutLukhu />
        <HelpCenter />
        <SubscriptionCard />
      </div>
      <div className="luhku-footer-line" />
      <p>
        {`Copyright @ Lukhu Inc. ${new Date().getFullYear()} All Rights Reserved.`}

      </p>

    </div>
  );
}
