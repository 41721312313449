import React from 'react';
import LinksController from '../../../controllers/LinksController';
import SocialController from '../../../controllers/SocialController';
import SocialIconCard from '../SocialIconCard';

export default function LuhkuSocialCard() {
  const icons = new SocialController().socials;
  const links = LinksController;
  return (
    <div className="section-columns">
      <h4>
        Lukhu Inc.
      </h4>
      <p>
        The Operating System for Fashion Ecommerce in Africa.
      </p>
      <div className="store-links-conatiner">
        <a href={links.playStoreLink} target="_blank" rel="noreferrer noopener">
          <div className="play-store-icon" />
        </a>
        <a href={links.appleStoreLink} target="_blank" rel="noreferrer noopener">
          <div className="apple-store-icon" />
        </a>
      </div>
      <div className="social-icons-row">
        {icons.map((i) => (<SocialIconCard key={`${i.icon}-footer-socials`} icon={i.icon} link={i.link} />))}
      </div>
    </div>
  );
}
