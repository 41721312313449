import React from 'react';
import PropTypes from 'prop-types';

export default function SocialIconCard(props) {
  const { icon, link } = props;

  if (link === '') {
    return (
      <div className="social-icon-card">
        <div className={`social-icon ${icon}`} />
      </div>
    );
  }
  return (
    <a className="social-icon-card" href={link} target="_blank" rel="noreferrer noopener">
      <div className={`social-icon ${icon}`} />
    </a>
  );
}

SocialIconCard.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
