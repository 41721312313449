import React from 'react';
import LinksController from '../../controllers/LinksController';
import LukhuLogo from './LukhuLogo';
import SearchContainer from './SearchContainer';
import './styles.css';

/**
 * It returns a div with a LukhuLogo, a SearchContainer, and two buttons
 * @returns A div with the className of navigation-bar.
 */
export default function NavigationBar() {
  const links = LinksController;
  return (
    <div className="navigation-bar">
      <LukhuLogo />
      <SearchContainer />
      <a href={links.buyerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button shop-now-btn btn-text">
        Shop Now
      </a>
      <a href={links.sellerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button start-selling-btn btn-text">
        Start Selling
      </a>
    </div>
  );
}
