import React from 'react';
import PropTypes from 'prop-types';

export default function SimplicityCard(props) {
  const { className, title, content } = props;
  return (
    <div className="simplicity-card">
      <div className="image-section">
        <div className={`simplicity-card-images ${className}`} />
      </div>
      <h4 className="card-text card-title-style">{title}</h4>
      <p className="card-text card-content">{content }</p>
    </div>
  );
}

SimplicityCard.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
