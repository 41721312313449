import React from 'react';
import LinksController from '../../controllers/LinksController';

/**
 * It returns a div with a search icon and a search text
 * @returns A div with a span and a div inside of it.
 */
export default function SearchContainer() {
  return (
    <div className="search-container">
      {/* <div className="search-icon">Icon</div> */}
      <span className="material-symbols-outlined search-icon">
        search
      </span>
      <a href={LinksController.searchLink} target="_blank" rel="noreferrer noopener" className="search-text"> Search for categories, items or shops</a>
    </div>
  );
}
