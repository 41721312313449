import React from 'react';
import SimplicityCards from './simplicity_cards/SimplicityCards';
import './styles.css';

export default function LuhkuSimplicity() {
  return (
    <div className="luhku-simplicity home-section">
      <h2 className="section-title">
        We make selling  Simple
      </h2>
      {/* <i className="simple-undeline-mobile" /> */}
      <p className="section-sub-title">
        Tap into a large community of fashion buyers. Enjoy simplified
        shop management, payments and delivery on one app!
      </p>
      <SimplicityCards />
    </div>
  );
}
