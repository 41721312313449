import React from 'react';
import InfoCard from './InfoCard';
import './styles.css';

export default function LuhkuInfoCards() {
  const cardsData = [
    {
      title: 'Wide Variety',
      icon: 'shopping-bag',
      content: 'Browse though thousands of items across womenswear, menswear, and kidswear categories.',
    },
    {
      title: 'Secure Payments',
      icon: 'empty-wallet-times',
      content: 'Pay for your items securely using the Lukhu Wallet - Your funds are only released to the seller once you receive your item and confirm that it’s OK.',
    },
    {
      title: 'Convenient Delivery',
      icon: 'truck-remove',
      content: 'With our doorstep delivery and naighborhood pick-up points, we guarantee deliveries are convenient and affordable.',
    },
  ];
  return (
    <div className="luhku-info-cards">
      {
cardsData.map((d, i) => (
  <InfoCard
    key={`${d.title}-luhku-info-card-${i.toString()}`}
    title={d.title}
    icon={d.icon}
    content={d.content}
  />
))
}
    </div>
  );
}
