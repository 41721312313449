import React from 'react';
import PropTypes from 'prop-types';

export default function InfoCard(props) {
  const { icon, title, content } = props;
  return (
    <div className="info-card">
      <div className="icon-container">
        <div
          className={`card-icon ${icon}`}
        />
      </div>
      <h3 className="title-text">
        {title}
      </h3>
      <p className="card-content-text">
        {content}
      </p>
    </div>
  );
}

InfoCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
