export default class SocialController {
  constructor() {
    this.socials = [
      {
        name: 'facebook',
        link: 'https://facebook.com/teamlukhu',
        icon: 'fb-icon',
      },
      {
        name: 'twitter',
        link: 'https://twitter.com/LukhuHQ',
        icon: 'twitter-icon',
      },
      // {
      //   name: 'youtube',
      //   link: 'https://www.youtube.com/yourname',
      //   icon: 'yt-icon',
      // },
      {
        name: 'whatsapp',
        link: 'https://api.whatsapp.com/send?phone=254746553470',
        icon: 'wp-icon',
      },
      {
        name: 'instagram',
        link: 'https://instagram.com/lukhu.app',
        icon: 'ig-icon',
      },
    ];

    this.socialsSepared = [
      {
        name: 'facebook',
        link: 'https://facebook.com/teamlukhu',
        icon: 'fb-icon',
      },
      {
        name: 'line',
        link: '',
        icon: 'line-icon',
      },
      {
        name: 'twitter',
        link: 'https://twitter.com/LukhuHQ',
        icon: 'twitter-icon',
      },
      {
        name: 'line',
        link: '',
        icon: 'line-icon',
      },
      {
        name: 'instagram',
        link: 'https://instagram.com/lukhu.app',
        icon: 'ig-icon',
      },
      // {
      //   name: 'line',
      //   link: '',
      //   icon: 'line-icon',
      // },
      // {
      //   name: 'youtube',
      //   link: 'https://www.youtube.com/yourname',
      //   icon: 'yt-icon',
      // },
    ];
  }
}
