/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import LinksController from '../../../../controllers/LinksController';
import SocialController from '../../../../controllers/SocialController';
import SocialIconCard from '../../../footer/SocialIconCard';
import './styles.css';

export default function SideNav() {
  /* Close/hide the sidenav */
  function closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  const icons = new SocialController().socialsSepared;
  const links = LinksController;
  return (
    <div id="mySidenav" className="sidenav">

      <div className="drawer-dot-logo" />
      <div className="drawer-closer" onClick={() => { closeNav(); }} />
      {/* <a href="close" className="closebtn" onClick={() => { closeNav(); }}>&times;</a> */}
      <a href="about">Home</a>
      <a href={links.sellerLink} target="_blank" rel="noreferrer noopener">For Sellers</a>
      <a href={links.buyerLink} target="_blank" rel="noreferrer noopener">For Buyers</a>
      <a href={links.blogLink} target="_blank" rel="noreferrer noopener">Why Lukhu?</a>
      <a href={links.playStoreLink} target="_blank" rel="noreferrer noopener">
        <div className="play-store-icon" />
      </a>
      <a href={links.appleStoreLink} target="_blank" rel="noreferrer noopener">
        <div className="apple-store-icon" />
      </a>
      <h4>Check us out</h4>
      <div className="nav-socials-row">
        {icons.map((i, j) => (<SocialIconCard key={`${i.icon}-nav-row${j.toString()}`} icon={i.icon} link={i.link} />))}
      </div>
    </div>
  );
}
