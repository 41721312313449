import React from 'react';
import LinksController from '../../../../controllers/LinksController';
import './styles.css';

export default function AppStoreDownloadCards() {
  const links = LinksController;
  return (
    <div className="mobile-app-store-download-card">
      <h2>Ready to Start?</h2>
      {/* <div className="ready-to-start-underline" /> */}
      <p>Join to become a part of Kenya’s largest fashion community </p>
      <div className="store-icons">
        <a href={links.playStoreLink} target="_blank" rel="noreferrer noopener">
          <div className="store-link-mobile play-store-icon-mobile" />
        </a>
        <a href={links.appleStoreLink} target="_blank" rel="noreferrer noopener">
          <div className="store-link-mobile apple-store-icon-mobile" />
        </a>
      </div>
    </div>
  );
}
