import React from 'react';
import SimplicityCard from './SimplicityCard';
import './styles.css';

export default function SimplicityCards() {
  const cardsData = [
    {
      className: 'sell-for-free',
      title: 'Sell for free and manage your shop in one place',
      content: 'List your items in less than 3 minutes, access a large market of buyers with the tools you need to manage and grow your fashion business online.',
    },
    {
      className: 'enjoy-secure-payment',
      title: 'Enjoy secure payments and business management tools',
      content: 'Get paid immediately into your secure Lukhu wallet once yout item is successfully delivered and ensure your funds are secure..',
    },
    {
      className: 'amazing-delivery',
      title: 'Our delivery system ensures convenience and affordability',
      content: 'We pick items from you and deliver them directly to your customers with amazing biodegradable packaging. All for free.',
    },
  ];
  return (
    <div className="simplicity-cards">
      {
        cardsData.map((d) => (
          <SimplicityCard
            key={d.className}
            title={d.title}
            className={d.className}
            content={d.content}
          />
        ))
      }
    </div>
  );
}
