import React from 'react';
import LinksController from '../../../controllers/LinksController';
import './styles.css';

export default function ReminderCard() {
  return (
    <div className="reminder-card-container">
      <h4 className="insite-text">
        Whether you want to build a fashion empire or make extra cash
        by decluttering your closet, Lukhu is for you!
      </h4>
      <a href={LinksController.sellerLink} target="_blank" rel="noreferrer noopener" className="lukhu-button start-selling-btn-insite btn-text">
        Start Selling
      </a>
    </div>
  );
}
