import React from 'react';
import LinksController from '../../controllers/LinksController';

export default function HelpCenter() {
  const links = LinksController;
  return (
    <div className="section-columns">
      <h4>
        Help Center
      </h4>
      <a href={links.termsLink} target="_blank" rel="noreferrer noopener">Terms and Conditions</a>
      <a href={links.privacyLink} target="_blank" rel="noreferrer noopener">Privacy Policy</a>
      <a href={links.howToSell} target="_blank" rel="noreferrer noopener">Selling on Lukhu</a>
      <a href={links.howToBuy} target="_blank" rel="noreferrer noopener">Buying on Lukhu</a>
    </div>
  );
}
