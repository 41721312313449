/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './styles.css';

export default function MobileMenuBar() {
  function openNav() {
    document.getElementById('mySidenav').style.width = '100%';
  }
  return (
    <div className="mobile-navigation-bar">
      <div className="lukhu-menu-logo" />
      <span className="material-symbols-outlined menu-icon" onClick={() => { openNav(); }}>
        menu
      </span>
    </div>
  );
}
