import './App.css';
import LuhkuFooter from './components/footer/LuhkuFooter';
import MobileFooter from './components/footer/mobile/MobileFooter';
import FashionInsite from './components/home_sections/fashion_insite/FashionInsite';
import HomeInto from './components/home_sections/intro_container/HomeInto';
import LuhkuSimplicity from './components/home_sections/luhku_simplicity/LuhkuSimplicity';
import AppStoreDownloadCards from './components/home_sections/mobile/ready_to_start/AppStoreDownloadCards';
import ShoppingSection from './components/home_sections/shopping_section/ShoppingSection';
import ReminderCard from './components/home_sections/user_interaction/ReminderCard';
import SideNav from './components/navifation_bar/mobile/drawer/SideNav';
import MobileMenuBar from './components/navifation_bar/mobile/MobileMenuBar';
import NavigationBar from './components/navifation_bar/NavigationBar';

function App() {
  return (
    <div className="App">
      <SideNav />
      <div id="main">
        <NavigationBar />
        <HomeInto />
        <ShoppingSection />
        <LuhkuSimplicity />
        <ReminderCard />
        <FashionInsite />
        <AppStoreDownloadCards />
        <LuhkuFooter />
        <MobileFooter />
        <MobileMenuBar />
      </div>
    </div>
  );
}

export default App;
