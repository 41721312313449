import React from 'react';
import LinksController from '../../../controllers/LinksController';
import './styles.css';

export default function FashionInsite() {
  const links = LinksController;

  return (
    <div className="fashion-insite">
      {/* <h3>Buy, Sell and Discover amazing fashion on Lukhu!</h3> */}
      <div className="store-links-conatiner app-links">
        <a href={links.playStoreLink} target="_blank" rel="noreferrer noopener">
          <div className="play-store-icon" />
        </a>
        <a href={links.appleStoreLink} target="_blank" rel="noreferrer noopener">
          <div className="apple-store-icon" />
        </a>
      </div>
      {/* <div className="amazing-fashion-image" /> */}
    </div>
  );
}
