import apiConstants from './constants';

export default async function subscribeToMailingList(email) {
  const { prodBaseUrl, stagingBaseUrl, prod } = apiConstants;
  const url = `${prod ? prodBaseUrl : stagingBaseUrl}/users/mailing/subscribe`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
  return response.json();
}
